// @generated by protobuf-ts 2.9.1
// @generated from protobuf file "discord.proto" (package "discord_protos.discord_users.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Int64Value } from "./google/protobuf/wrappers";
import { FloatValue } from "./google/protobuf/wrappers";
import { UInt64Value } from "./google/protobuf/wrappers";
import { Int32Value } from "./google/protobuf/wrappers";
import { UInt32Value } from "./google/protobuf/wrappers";
import { BoolValue } from "./google/protobuf/wrappers";
import { StringValue } from "./google/protobuf/wrappers";
import { Timestamp } from "./google/protobuf/timestamp";
/**
 * @generated from protobuf message discord_protos.discord_users.v1.Versions
 */
export interface Versions {
    /**
     * @generated from protobuf field: uint32 client_version = 1;
     */
    clientVersion: number;
    /**
     * @generated from protobuf field: uint32 server_version = 2;
     */
    serverVersion: number;
    /**
     * @generated from protobuf field: uint32 data_version = 3;
     */
    dataVersion: number;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.InboxSettings
 */
export interface InboxSettings {
    /**
     * @generated from protobuf field: discord_protos.discord_users.v1.InboxTab current_tab = 1;
     */
    currentTab: InboxTab;
    /**
     * @generated from protobuf field: bool viewed_tutorial = 2;
     */
    viewedTutorial: boolean;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.ChannelSettings
 */
export interface ChannelSettings {
    /**
     * @generated from protobuf field: bool collapsed_in_inbox = 1;
     */
    collapsedInInbox: boolean;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.GuildSettings
 */
export interface GuildSettings {
    /**
     * @generated from protobuf field: map<fixed64, discord_protos.discord_users.v1.ChannelSettings> channels = 1;
     */
    channels: {
        [key: string]: ChannelSettings;
    };
    /**
     * @generated from protobuf field: uint32 hub_progress = 2;
     */
    hubProgress: number;
    /**
     * @generated from protobuf field: uint32 guild_onboarding_progress = 3;
     */
    guildOnboardingProgress: number;
    /**
     * @generated from protobuf field: optional google.protobuf.Timestamp guild_recents_dismissed_at = 4;
     */
    guildRecentsDismissedAt?: Timestamp;
    /**
     * @generated from protobuf field: bytes dismissed_guild_content = 5;
     */
    dismissedGuildContent: Uint8Array;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.AllGuildSettings
 */
export interface AllGuildSettings {
    /**
     * @generated from protobuf field: map<fixed64, discord_protos.discord_users.v1.GuildSettings> guilds = 1;
     */
    guilds: {
        [key: string]: GuildSettings;
    };
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.UserContentSettings
 */
export interface UserContentSettings {
    /**
     * @generated from protobuf field: bytes dismissed_contents = 1;
     */
    dismissedContents: Uint8Array;
    /**
     * @generated from protobuf field: optional google.protobuf.StringValue last_dismissed_outbound_promotion_start_date = 2;
     */
    lastDismissedOutboundPromotionStartDate?: StringValue;
    /**
     * @generated from protobuf field: optional google.protobuf.Timestamp premium_tier_0_modal_dismissed_at = 3;
     */
    premiumTier0ModalDismissedAt?: Timestamp;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.VideoFilterBackgroundBlur
 */
export interface VideoFilterBackgroundBlur {
    /**
     * @generated from protobuf field: bool use_blur = 1;
     */
    useBlur: boolean;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.VideoFilterAsset
 */
export interface VideoFilterAsset {
    /**
     * @generated from protobuf field: fixed64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: string asset_hash = 2;
     */
    assetHash: string;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.SoundboardSettings
 */
export interface SoundboardSettings {
    /**
     * @generated from protobuf field: float volume = 1;
     */
    volume: number;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.VoiceAndVideoSettings
 */
export interface VoiceAndVideoSettings {
    /**
     * @generated from protobuf field: discord_protos.discord_users.v1.VideoFilterBackgroundBlur blur = 1;
     */
    blur?: VideoFilterBackgroundBlur;
    /**
     * @generated from protobuf field: uint32 preset_option = 2;
     */
    presetOption: number;
    /**
     * @generated from protobuf field: discord_protos.discord_users.v1.VideoFilterAsset custom_asset = 3;
     */
    customAsset?: VideoFilterAsset;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue always_preview_video = 5;
     */
    alwaysPreviewVideo?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.UInt32Value afk_timeout = 6;
     */
    afkTimeout?: UInt32Value;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue stream_notifications_enabled = 7;
     */
    streamNotificationsEnabled?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue native_phone_integration_enabled = 8;
     */
    nativePhoneIntegrationEnabled?: BoolValue;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.SoundboardSettings soundboard_settings = 9;
     */
    soundboardSettings?: SoundboardSettings;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.TextAndImagesSettings
 */
export interface TextAndImagesSettings {
    /**
     * @generated from protobuf field: optional google.protobuf.StringValue diversity_surrogate = 1;
     */
    diversitySurrogate?: StringValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue use_rich_chat_input = 2;
     */
    useRichChatInput?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue use_thread_sidebar = 3;
     */
    useThreadSidebar?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.StringValue render_spoilers = 4;
     */
    renderSpoilers?: StringValue;
    /**
     * @generated from protobuf field: repeated string emoji_picker_collapsed_sections = 5;
     */
    emojiPickerCollapsedSections: string[];
    /**
     * @generated from protobuf field: repeated string sticker_picker_collapsed_sections = 6;
     */
    stickerPickerCollapsedSections: string[];
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue view_image_descriptions = 7;
     */
    viewImageDescriptions?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue show_command_suggestions = 8;
     */
    showCommandSuggestions?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue inline_attachment_media = 9;
     */
    inlineAttachmentMedia?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue inline_embed_media = 10;
     */
    inlineEmbedMedia?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue gif_auto_play = 11;
     */
    gifAutoPlay?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue render_embeds = 12;
     */
    renderEmbeds?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue render_reactions = 13;
     */
    renderReactions?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue animate_emoji = 14;
     */
    animateEmoji?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.UInt32Value animate_stickers = 15;
     */
    animateStickers?: UInt32Value;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue enable_tts_command = 16;
     */
    enableTtsCommand?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue message_display_compact = 17;
     */
    messageDisplayCompact?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.UInt32Value explicit_content_filter = 19;
     */
    explicitContentFilter?: UInt32Value;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue view_nsfw_guilds = 20;
     */
    viewNsfwGuilds?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue convert_emoticons = 21;
     */
    convertEmoticons?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue expression_suggestions_enabled = 22;
     */
    expressionSuggestionsEnabled?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue view_nsfw_commands = 23;
     */
    viewNsfwCommands?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue use_legacy_chat_input = 24;
     */
    useLegacyChatInput?: BoolValue;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.NotificationSettings
 */
export interface NotificationSettings {
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue show_in_app_notifications = 1;
     */
    showInAppNotifications?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue notify_friends_on_go_live = 2;
     */
    notifyFriendsOnGoLive?: BoolValue;
    /**
     * @generated from protobuf field: fixed64 notification_center_acked_before_id = 3;
     */
    notificationCenterAckedBeforeId: bigint;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.PrivacySettings
 */
export interface PrivacySettings {
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue allow_activity_party_privacy_friends = 1;
     */
    allowActivityPartyPrivacyFriends?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue allow_activity_party_privacy_voice_channel = 2;
     */
    allowActivityPartyPrivacyVoiceChannel?: BoolValue;
    /**
     * @generated from protobuf field: repeated fixed64 restricted_guild_ids = 3;
     */
    restrictedGuildIds: bigint[];
    /**
     * @generated from protobuf field: bool default_guilds_restricted = 4;
     */
    defaultGuildsRestricted: boolean;
    /**
     * @generated from protobuf field: bool allow_accessibility_detection = 7;
     */
    allowAccessibilityDetection: boolean;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue detect_platform_accounts = 8;
     */
    detectPlatformAccounts?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue passwordless = 9;
     */
    passwordless?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue contact_sync_enabled = 10;
     */
    contactSyncEnabled?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.UInt32Value friend_source_flags = 11;
     */
    friendSourceFlags?: UInt32Value;
    /**
     * @generated from protobuf field: optional google.protobuf.UInt32Value friend_discovery_flags = 12;
     */
    friendDiscoveryFlags?: UInt32Value;
    /**
     * @generated from protobuf field: repeated fixed64 activity_restricted_guild_ids = 13;
     */
    activityRestrictedGuildIds: bigint[];
    /**
     * @generated from protobuf field: discord_protos.discord_users.v1.GuildActivityStatusRestrictionDefault default_guilds_activity_restricted = 14;
     */
    defaultGuildsActivityRestricted: GuildActivityStatusRestrictionDefault;
    /**
     * @generated from protobuf field: repeated fixed64 activity_joining_restricted_guild_ids = 15;
     */
    activityJoiningRestrictedGuildIds: bigint[];
    /**
     * @generated from protobuf field: repeated fixed64 message_request_restricted_guild_ids = 16;
     */
    messageRequestRestrictedGuildIds: bigint[];
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue default_message_request_restricted = 17;
     */
    defaultMessageRequestRestricted?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue drops_opted_out = 18;
     */
    dropsOptedOut?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue non_spam_retraining_opt_in = 19;
     */
    nonSpamRetrainingOptIn?: BoolValue;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.DebugSettings
 */
export interface DebugSettings {
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue rtc_panel_show_voice_states = 1;
     */
    rtcPanelShowVoiceStates?: BoolValue;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.GameLibrarySettings
 */
export interface GameLibrarySettings {
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue install_shortcut_desktop = 1;
     */
    installShortcutDesktop?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue install_shortcut_start_menu = 2;
     */
    installShortcutStartMenu?: BoolValue;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue disable_games_tab = 3;
     */
    disableGamesTab?: BoolValue;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.CustomStatus
 */
export interface CustomStatus {
    /**
     * @generated from protobuf field: string text = 1;
     */
    text: string;
    /**
     * @generated from protobuf field: fixed64 emoji_id = 2;
     */
    emojiId: bigint;
    /**
     * @generated from protobuf field: string emoji_name = 3;
     */
    emojiName: string;
    /**
     * @generated from protobuf field: fixed64 expires_at_ms = 4;
     */
    expiresAtMs: bigint;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.StatusSettings
 */
export interface StatusSettings {
    /**
     * @generated from protobuf field: optional google.protobuf.StringValue status = 1;
     */
    status?: StringValue;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.CustomStatus custom_status = 2;
     */
    customStatus?: CustomStatus;
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue show_current_game = 3;
     */
    showCurrentGame?: BoolValue;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.LocalizationSettings
 */
export interface LocalizationSettings {
    /**
     * @generated from protobuf field: optional google.protobuf.StringValue locale = 1;
     */
    locale?: StringValue;
    /**
     * @generated from protobuf field: optional google.protobuf.Int32Value timezone_offset = 2;
     */
    timezoneOffset?: Int32Value;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.ClientThemeSettings
 */
export interface ClientThemeSettings {
    /**
     * @generated from protobuf field: optional google.protobuf.UInt64Value primary_color = 1;
     */
    primaryColor?: UInt64Value;
    /**
     * @generated from protobuf field: optional google.protobuf.UInt32Value background_gradient_preset_id = 2;
     */
    backgroundGradientPresetId?: UInt32Value;
    /**
     * @generated from protobuf field: optional google.protobuf.FloatValue background_gradient_angle = 3;
     */
    backgroundGradientAngle?: FloatValue;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.AppearanceSettings
 */
export interface AppearanceSettings {
    /**
     * @generated from protobuf field: discord_protos.discord_users.v1.Theme theme = 1;
     */
    theme: Theme;
    /**
     * @generated from protobuf field: bool developer_mode = 2;
     */
    developerMode: boolean;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.ClientThemeSettings client_theme_settings = 3;
     */
    clientThemeSettings?: ClientThemeSettings;
    /**
     * @generated from protobuf field: bool mobile_redesign_disabled = 4;
     */
    mobileRedesignDisabled: boolean;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.GuildFolder
 */
export interface GuildFolder {
    /**
     * @generated from protobuf field: repeated fixed64 guild_ids = 1;
     */
    guildIds: bigint[];
    /**
     * @generated from protobuf field: optional google.protobuf.Int64Value id = 2;
     */
    id?: Int64Value;
    /**
     * @generated from protobuf field: optional google.protobuf.StringValue name = 3;
     */
    name?: StringValue;
    /**
     * @generated from protobuf field: optional google.protobuf.UInt64Value color = 4;
     */
    color?: UInt64Value;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.GuildFolders
 */
export interface GuildFolders {
    /**
     * @generated from protobuf field: repeated discord_protos.discord_users.v1.GuildFolder folders = 1;
     */
    folders: GuildFolder[];
    /**
     * @generated from protobuf field: repeated fixed64 guild_positions = 2;
     */
    guildPositions: bigint[];
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.FavoriteChannel
 */
export interface FavoriteChannel {
    /**
     * @generated from protobuf field: string nickname = 1;
     */
    nickname: string;
    /**
     * @generated from protobuf field: discord_protos.discord_users.v1.FavoriteChannelType type = 2;
     */
    type: FavoriteChannelType;
    /**
     * @generated from protobuf field: uint32 position = 3;
     */
    position: number;
    /**
     * @generated from protobuf field: fixed64 parent_id = 4;
     */
    parentId: bigint;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.Favorites
 */
export interface Favorites {
    /**
     * @generated from protobuf field: map<fixed64, discord_protos.discord_users.v1.FavoriteChannel> favorite_channels = 1;
     */
    favoriteChannels: {
        [key: string]: FavoriteChannel;
    };
    /**
     * @generated from protobuf field: bool muted = 2;
     */
    muted: boolean;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.AudioContextSetting
 */
export interface AudioContextSetting {
    /**
     * @generated from protobuf field: bool muted = 1;
     */
    muted: boolean;
    /**
     * @generated from protobuf field: float volume = 2;
     */
    volume: number;
    /**
     * @generated from protobuf field: fixed64 modified_at = 3;
     */
    modifiedAt: bigint;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.AudioSettings
 */
export interface AudioSettings {
    /**
     * @generated from protobuf field: map<fixed64, discord_protos.discord_users.v1.AudioContextSetting> user = 1;
     */
    user: {
        [key: string]: AudioContextSetting;
    };
    /**
     * @generated from protobuf field: map<fixed64, discord_protos.discord_users.v1.AudioContextSetting> stream = 2;
     */
    stream: {
        [key: string]: AudioContextSetting;
    };
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.CommunitiesSettings
 */
export interface CommunitiesSettings {
    /**
     * @generated from protobuf field: optional google.protobuf.BoolValue disable_home_auto_nav = 1;
     */
    disableHomeAutoNav?: BoolValue;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.PreloadedUserSettings
 */
export interface PreloadedUserSettings {
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.Versions versions = 1;
     */
    versions?: Versions;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.InboxSettings inbox = 2;
     */
    inbox?: InboxSettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.AllGuildSettings guilds = 3;
     */
    guilds?: AllGuildSettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.UserContentSettings user_content = 4;
     */
    userContent?: UserContentSettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.VoiceAndVideoSettings voice_and_video = 5;
     */
    voiceAndVideo?: VoiceAndVideoSettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.TextAndImagesSettings text_and_images = 6;
     */
    textAndImages?: TextAndImagesSettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.NotificationSettings notifications = 7;
     */
    notifications?: NotificationSettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.PrivacySettings privacy = 8;
     */
    privacy?: PrivacySettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.DebugSettings debug = 9;
     */
    debug?: DebugSettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.GameLibrarySettings game_library = 10;
     */
    gameLibrary?: GameLibrarySettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.StatusSettings status = 11;
     */
    status?: StatusSettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.LocalizationSettings localization = 12;
     */
    localization?: LocalizationSettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.AppearanceSettings appearance = 13;
     */
    appearance?: AppearanceSettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.GuildFolders guild_folders = 14;
     */
    guildFolders?: GuildFolders;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.Favorites favorites = 15;
     */
    favorites?: Favorites;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.AudioSettings audio_context_settings = 16;
     */
    audioContextSettings?: AudioSettings;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.CommunitiesSettings communities = 17;
     */
    communities?: CommunitiesSettings;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.FavoriteGIF
 */
export interface FavoriteGIF {
    /**
     * @generated from protobuf field: discord_protos.discord_users.v1.GIFType format = 1;
     */
    format: GIFType;
    /**
     * @generated from protobuf field: string src = 2;
     */
    src: string;
    /**
     * @generated from protobuf field: uint32 width = 3;
     */
    width: number;
    /**
     * @generated from protobuf field: uint32 height = 4;
     */
    height: number;
    /**
     * @generated from protobuf field: uint32 order = 5;
     */
    order: number;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.FavoriteGIFs
 */
export interface FavoriteGIFs {
    /**
     * @generated from protobuf field: map<string, discord_protos.discord_users.v1.FavoriteGIF> gifs = 1;
     */
    gifs: {
        [key: string]: FavoriteGIF;
    };
    /**
     * @generated from protobuf field: bool hide_tooltip = 2;
     */
    hideTooltip: boolean;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.FavoriteStickers
 */
export interface FavoriteStickers {
    /**
     * @generated from protobuf field: repeated fixed64 sticker_ids = 1;
     */
    stickerIds: bigint[];
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.FrecencyItem
 */
export interface FrecencyItem {
    /**
     * @generated from protobuf field: uint32 total_uses = 1;
     */
    totalUses: number;
    /**
     * @generated from protobuf field: repeated uint64 recent_uses = 2;
     */
    recentUses: bigint[];
    /**
     * @generated from protobuf field: int32 frecency = 3;
     */
    frecency: number;
    /**
     * @generated from protobuf field: int32 score = 4;
     */
    score: number;
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.StickerFrecency
 */
export interface StickerFrecency {
    /**
     * @generated from protobuf field: map<fixed64, discord_protos.discord_users.v1.FrecencyItem> stickers = 1;
     */
    stickers: {
        [key: string]: FrecencyItem;
    };
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.FavoriteEmojis
 */
export interface FavoriteEmojis {
    /**
     * @generated from protobuf field: repeated string emojis = 1;
     */
    emojis: string[];
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.EmojiFrecency
 */
export interface EmojiFrecency {
    /**
     * @generated from protobuf field: map<string, discord_protos.discord_users.v1.FrecencyItem> emojis = 1;
     */
    emojis: {
        [key: string]: FrecencyItem;
    };
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.ApplicationCommandFrecency
 */
export interface ApplicationCommandFrecency {
    /**
     * @generated from protobuf field: map<string, discord_protos.discord_users.v1.FrecencyItem> application_commands = 1;
     */
    applicationCommands: {
        [key: string]: FrecencyItem;
    };
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.FavoriteSoundboardSounds
 */
export interface FavoriteSoundboardSounds {
    /**
     * @generated from protobuf field: repeated fixed64 sound_ids = 1;
     */
    soundIds: bigint[];
}
/**
 * @generated from protobuf message discord_protos.discord_users.v1.FrecencyUserSettings
 */
export interface FrecencyUserSettings {
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.Versions versions = 1;
     */
    versions?: Versions;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.FavoriteGIFs favorite_gifs = 2;
     */
    favoriteGifs?: FavoriteGIFs;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.FavoriteStickers favorite_stickers = 3;
     */
    favoriteStickers?: FavoriteStickers;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.StickerFrecency sticker_frecency = 4;
     */
    stickerFrecency?: StickerFrecency;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.FavoriteEmojis favorite_emojis = 5;
     */
    favoriteEmojis?: FavoriteEmojis;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.EmojiFrecency emoji_frecency = 6;
     */
    emojiFrecency?: EmojiFrecency;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.ApplicationCommandFrecency application_command_frecency = 7;
     */
    applicationCommandFrecency?: ApplicationCommandFrecency;
    /**
     * @generated from protobuf field: optional discord_protos.discord_users.v1.FavoriteSoundboardSounds favorite_soundboard_sounds = 8;
     */
    favoriteSoundboardSounds?: FavoriteSoundboardSounds;
}
/**
 * @generated from protobuf enum discord_protos.discord_users.v1.InboxTab
 */
export enum InboxTab {
    /**
     * @generated from protobuf enum value: UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: MENTIONS = 1;
     */
    MENTIONS = 1,
    /**
     * @generated from protobuf enum value: UNREADS = 2;
     */
    UNREADS = 2,
    /**
     * @generated from protobuf enum value: TODOS = 3;
     */
    TODOS = 3,
    /**
     * @generated from protobuf enum value: FOR_YOU = 4;
     */
    FOR_YOU = 4
}
/**
 * @generated from protobuf enum discord_protos.discord_users.v1.GuildActivityStatusRestrictionDefault
 */
export enum GuildActivityStatusRestrictionDefault {
    /**
     * @generated from protobuf enum value: OFF = 0;
     */
    OFF = 0,
    /**
     * @generated from protobuf enum value: ON_FOR_LARGE_GUILDS = 1;
     */
    ON_FOR_LARGE_GUILDS = 1
}
/**
 * @generated from protobuf enum discord_protos.discord_users.v1.Theme
 */
export enum Theme {
    /**
     * @generated from protobuf enum value: UNSET = 0;
     */
    UNSET = 0,
    /**
     * @generated from protobuf enum value: DARK = 1;
     */
    DARK = 1,
    /**
     * @generated from protobuf enum value: LIGHT = 2;
     */
    LIGHT = 2
}
/**
 * @generated from protobuf enum discord_protos.discord_users.v1.FavoriteChannelType
 */
export enum FavoriteChannelType {
    /**
     * @generated from protobuf enum value: UNSET_FAVORITE_CHANNEL_TYPE = 0;
     */
    UNSET_FAVORITE_CHANNEL_TYPE = 0,
    /**
     * @generated from protobuf enum value: REFERENCE_ORIGINAL = 1;
     */
    REFERENCE_ORIGINAL = 1,
    /**
     * @generated from protobuf enum value: CATEGORY = 2;
     */
    CATEGORY = 2
}
/**
 * @generated from protobuf enum discord_protos.discord_users.v1.GIFType
 */
export enum GIFType {
    /**
     * @generated from protobuf enum value: NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: IMAGE = 1;
     */
    IMAGE = 1,
    /**
     * @generated from protobuf enum value: VIDEO = 2;
     */
    VIDEO = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Versions$Type extends MessageType<Versions> {
    constructor() {
        super("discord_protos.discord_users.v1.Versions", [
            { no: 1, name: "client_version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "server_version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "data_version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<Versions>): Versions {
        const message = { clientVersion: 0, serverVersion: 0, dataVersion: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Versions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Versions): Versions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 client_version */ 1:
                    message.clientVersion = reader.uint32();
                    break;
                case /* uint32 server_version */ 2:
                    message.serverVersion = reader.uint32();
                    break;
                case /* uint32 data_version */ 3:
                    message.dataVersion = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Versions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 client_version = 1; */
        if (message.clientVersion !== 0)
            writer.tag(1, WireType.Varint).uint32(message.clientVersion);
        /* uint32 server_version = 2; */
        if (message.serverVersion !== 0)
            writer.tag(2, WireType.Varint).uint32(message.serverVersion);
        /* uint32 data_version = 3; */
        if (message.dataVersion !== 0)
            writer.tag(3, WireType.Varint).uint32(message.dataVersion);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.Versions
 */
export const Versions = new Versions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InboxSettings$Type extends MessageType<InboxSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.InboxSettings", [
            { no: 1, name: "current_tab", kind: "enum", T: () => ["discord_protos.discord_users.v1.InboxTab", InboxTab] },
            { no: 2, name: "viewed_tutorial", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<InboxSettings>): InboxSettings {
        const message = { currentTab: 0, viewedTutorial: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InboxSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InboxSettings): InboxSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* discord_protos.discord_users.v1.InboxTab current_tab */ 1:
                    message.currentTab = reader.int32();
                    break;
                case /* bool viewed_tutorial */ 2:
                    message.viewedTutorial = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InboxSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* discord_protos.discord_users.v1.InboxTab current_tab = 1; */
        if (message.currentTab !== 0)
            writer.tag(1, WireType.Varint).int32(message.currentTab);
        /* bool viewed_tutorial = 2; */
        if (message.viewedTutorial !== false)
            writer.tag(2, WireType.Varint).bool(message.viewedTutorial);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.InboxSettings
 */
export const InboxSettings = new InboxSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelSettings$Type extends MessageType<ChannelSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.ChannelSettings", [
            { no: 1, name: "collapsed_in_inbox", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ChannelSettings>): ChannelSettings {
        const message = { collapsedInInbox: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChannelSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChannelSettings): ChannelSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool collapsed_in_inbox */ 1:
                    message.collapsedInInbox = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChannelSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool collapsed_in_inbox = 1; */
        if (message.collapsedInInbox !== false)
            writer.tag(1, WireType.Varint).bool(message.collapsedInInbox);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.ChannelSettings
 */
export const ChannelSettings = new ChannelSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuildSettings$Type extends MessageType<GuildSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.GuildSettings", [
            { no: 1, name: "channels", kind: "map", K: 6 /*ScalarType.FIXED64*/, V: { kind: "message", T: () => ChannelSettings } },
            { no: 2, name: "hub_progress", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "guild_onboarding_progress", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "guild_recents_dismissed_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "dismissed_guild_content", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GuildSettings>): GuildSettings {
        const message = { channels: {}, hubProgress: 0, guildOnboardingProgress: 0, dismissedGuildContent: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuildSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuildSettings): GuildSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<fixed64, discord_protos.discord_users.v1.ChannelSettings> channels */ 1:
                    this.binaryReadMap1(message.channels, reader, options);
                    break;
                case /* uint32 hub_progress */ 2:
                    message.hubProgress = reader.uint32();
                    break;
                case /* uint32 guild_onboarding_progress */ 3:
                    message.guildOnboardingProgress = reader.uint32();
                    break;
                case /* optional google.protobuf.Timestamp guild_recents_dismissed_at */ 4:
                    message.guildRecentsDismissedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.guildRecentsDismissedAt);
                    break;
                case /* bytes dismissed_guild_content */ 5:
                    message.dismissedGuildContent = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GuildSettings["channels"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GuildSettings["channels"] | undefined, val: GuildSettings["channels"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.fixed64().toString();
                    break;
                case 2:
                    val = ChannelSettings.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field discord_protos.discord_users.v1.GuildSettings.channels");
            }
        }
        map[key ?? "0"] = val ?? ChannelSettings.create();
    }
    internalBinaryWrite(message: GuildSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<fixed64, discord_protos.discord_users.v1.ChannelSettings> channels = 1; */
        for (let k of Object.keys(message.channels)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Bit64).fixed64(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            ChannelSettings.internalBinaryWrite(message.channels[k], writer, options);
            writer.join().join();
        }
        /* uint32 hub_progress = 2; */
        if (message.hubProgress !== 0)
            writer.tag(2, WireType.Varint).uint32(message.hubProgress);
        /* uint32 guild_onboarding_progress = 3; */
        if (message.guildOnboardingProgress !== 0)
            writer.tag(3, WireType.Varint).uint32(message.guildOnboardingProgress);
        /* optional google.protobuf.Timestamp guild_recents_dismissed_at = 4; */
        if (message.guildRecentsDismissedAt)
            Timestamp.internalBinaryWrite(message.guildRecentsDismissedAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bytes dismissed_guild_content = 5; */
        if (message.dismissedGuildContent.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.dismissedGuildContent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.GuildSettings
 */
export const GuildSettings = new GuildSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllGuildSettings$Type extends MessageType<AllGuildSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.AllGuildSettings", [
            { no: 1, name: "guilds", kind: "map", K: 6 /*ScalarType.FIXED64*/, V: { kind: "message", T: () => GuildSettings } }
        ]);
    }
    create(value?: PartialMessage<AllGuildSettings>): AllGuildSettings {
        const message = { guilds: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllGuildSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllGuildSettings): AllGuildSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<fixed64, discord_protos.discord_users.v1.GuildSettings> guilds */ 1:
                    this.binaryReadMap1(message.guilds, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: AllGuildSettings["guilds"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AllGuildSettings["guilds"] | undefined, val: AllGuildSettings["guilds"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.fixed64().toString();
                    break;
                case 2:
                    val = GuildSettings.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field discord_protos.discord_users.v1.AllGuildSettings.guilds");
            }
        }
        map[key ?? "0"] = val ?? GuildSettings.create();
    }
    internalBinaryWrite(message: AllGuildSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<fixed64, discord_protos.discord_users.v1.GuildSettings> guilds = 1; */
        for (let k of Object.keys(message.guilds)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Bit64).fixed64(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            GuildSettings.internalBinaryWrite(message.guilds[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.AllGuildSettings
 */
export const AllGuildSettings = new AllGuildSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserContentSettings$Type extends MessageType<UserContentSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.UserContentSettings", [
            { no: 1, name: "dismissed_contents", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "last_dismissed_outbound_promotion_start_date", kind: "message", T: () => StringValue },
            { no: 3, name: "premium_tier_0_modal_dismissed_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<UserContentSettings>): UserContentSettings {
        const message = { dismissedContents: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserContentSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserContentSettings): UserContentSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes dismissed_contents */ 1:
                    message.dismissedContents = reader.bytes();
                    break;
                case /* optional google.protobuf.StringValue last_dismissed_outbound_promotion_start_date */ 2:
                    message.lastDismissedOutboundPromotionStartDate = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.lastDismissedOutboundPromotionStartDate);
                    break;
                case /* optional google.protobuf.Timestamp premium_tier_0_modal_dismissed_at */ 3:
                    message.premiumTier0ModalDismissedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.premiumTier0ModalDismissedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserContentSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes dismissed_contents = 1; */
        if (message.dismissedContents.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.dismissedContents);
        /* optional google.protobuf.StringValue last_dismissed_outbound_promotion_start_date = 2; */
        if (message.lastDismissedOutboundPromotionStartDate)
            StringValue.internalBinaryWrite(message.lastDismissedOutboundPromotionStartDate, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.Timestamp premium_tier_0_modal_dismissed_at = 3; */
        if (message.premiumTier0ModalDismissedAt)
            Timestamp.internalBinaryWrite(message.premiumTier0ModalDismissedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.UserContentSettings
 */
export const UserContentSettings = new UserContentSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoFilterBackgroundBlur$Type extends MessageType<VideoFilterBackgroundBlur> {
    constructor() {
        super("discord_protos.discord_users.v1.VideoFilterBackgroundBlur", [
            { no: 1, name: "use_blur", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<VideoFilterBackgroundBlur>): VideoFilterBackgroundBlur {
        const message = { useBlur: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VideoFilterBackgroundBlur>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VideoFilterBackgroundBlur): VideoFilterBackgroundBlur {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool use_blur */ 1:
                    message.useBlur = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VideoFilterBackgroundBlur, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool use_blur = 1; */
        if (message.useBlur !== false)
            writer.tag(1, WireType.Varint).bool(message.useBlur);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.VideoFilterBackgroundBlur
 */
export const VideoFilterBackgroundBlur = new VideoFilterBackgroundBlur$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoFilterAsset$Type extends MessageType<VideoFilterAsset> {
    constructor() {
        super("discord_protos.discord_users.v1.VideoFilterAsset", [
            { no: 1, name: "id", kind: "scalar", T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "asset_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VideoFilterAsset>): VideoFilterAsset {
        const message = { id: 0n, assetHash: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VideoFilterAsset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VideoFilterAsset): VideoFilterAsset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* fixed64 id */ 1:
                    message.id = reader.fixed64().toBigInt();
                    break;
                case /* string asset_hash */ 2:
                    message.assetHash = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VideoFilterAsset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* fixed64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Bit64).fixed64(message.id);
        /* string asset_hash = 2; */
        if (message.assetHash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.assetHash);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.VideoFilterAsset
 */
export const VideoFilterAsset = new VideoFilterAsset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SoundboardSettings$Type extends MessageType<SoundboardSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.SoundboardSettings", [
            { no: 1, name: "volume", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<SoundboardSettings>): SoundboardSettings {
        const message = { volume: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SoundboardSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SoundboardSettings): SoundboardSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* float volume */ 1:
                    message.volume = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SoundboardSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* float volume = 1; */
        if (message.volume !== 0)
            writer.tag(1, WireType.Bit32).float(message.volume);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.SoundboardSettings
 */
export const SoundboardSettings = new SoundboardSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VoiceAndVideoSettings$Type extends MessageType<VoiceAndVideoSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.VoiceAndVideoSettings", [
            { no: 1, name: "blur", kind: "message", T: () => VideoFilterBackgroundBlur },
            { no: 2, name: "preset_option", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "custom_asset", kind: "message", T: () => VideoFilterAsset },
            { no: 5, name: "always_preview_video", kind: "message", T: () => BoolValue },
            { no: 6, name: "afk_timeout", kind: "message", T: () => UInt32Value },
            { no: 7, name: "stream_notifications_enabled", kind: "message", T: () => BoolValue },
            { no: 8, name: "native_phone_integration_enabled", kind: "message", T: () => BoolValue },
            { no: 9, name: "soundboard_settings", kind: "message", T: () => SoundboardSettings }
        ]);
    }
    create(value?: PartialMessage<VoiceAndVideoSettings>): VoiceAndVideoSettings {
        const message = { presetOption: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VoiceAndVideoSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VoiceAndVideoSettings): VoiceAndVideoSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* discord_protos.discord_users.v1.VideoFilterBackgroundBlur blur */ 1:
                    message.blur = VideoFilterBackgroundBlur.internalBinaryRead(reader, reader.uint32(), options, message.blur);
                    break;
                case /* uint32 preset_option */ 2:
                    message.presetOption = reader.uint32();
                    break;
                case /* discord_protos.discord_users.v1.VideoFilterAsset custom_asset */ 3:
                    message.customAsset = VideoFilterAsset.internalBinaryRead(reader, reader.uint32(), options, message.customAsset);
                    break;
                case /* optional google.protobuf.BoolValue always_preview_video */ 5:
                    message.alwaysPreviewVideo = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.alwaysPreviewVideo);
                    break;
                case /* optional google.protobuf.UInt32Value afk_timeout */ 6:
                    message.afkTimeout = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.afkTimeout);
                    break;
                case /* optional google.protobuf.BoolValue stream_notifications_enabled */ 7:
                    message.streamNotificationsEnabled = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.streamNotificationsEnabled);
                    break;
                case /* optional google.protobuf.BoolValue native_phone_integration_enabled */ 8:
                    message.nativePhoneIntegrationEnabled = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.nativePhoneIntegrationEnabled);
                    break;
                case /* optional discord_protos.discord_users.v1.SoundboardSettings soundboard_settings */ 9:
                    message.soundboardSettings = SoundboardSettings.internalBinaryRead(reader, reader.uint32(), options, message.soundboardSettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VoiceAndVideoSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* discord_protos.discord_users.v1.VideoFilterBackgroundBlur blur = 1; */
        if (message.blur)
            VideoFilterBackgroundBlur.internalBinaryWrite(message.blur, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* uint32 preset_option = 2; */
        if (message.presetOption !== 0)
            writer.tag(2, WireType.Varint).uint32(message.presetOption);
        /* discord_protos.discord_users.v1.VideoFilterAsset custom_asset = 3; */
        if (message.customAsset)
            VideoFilterAsset.internalBinaryWrite(message.customAsset, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue always_preview_video = 5; */
        if (message.alwaysPreviewVideo)
            BoolValue.internalBinaryWrite(message.alwaysPreviewVideo, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.UInt32Value afk_timeout = 6; */
        if (message.afkTimeout)
            UInt32Value.internalBinaryWrite(message.afkTimeout, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue stream_notifications_enabled = 7; */
        if (message.streamNotificationsEnabled)
            BoolValue.internalBinaryWrite(message.streamNotificationsEnabled, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue native_phone_integration_enabled = 8; */
        if (message.nativePhoneIntegrationEnabled)
            BoolValue.internalBinaryWrite(message.nativePhoneIntegrationEnabled, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.SoundboardSettings soundboard_settings = 9; */
        if (message.soundboardSettings)
            SoundboardSettings.internalBinaryWrite(message.soundboardSettings, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.VoiceAndVideoSettings
 */
export const VoiceAndVideoSettings = new VoiceAndVideoSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextAndImagesSettings$Type extends MessageType<TextAndImagesSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.TextAndImagesSettings", [
            { no: 1, name: "diversity_surrogate", kind: "message", T: () => StringValue },
            { no: 2, name: "use_rich_chat_input", kind: "message", T: () => BoolValue },
            { no: 3, name: "use_thread_sidebar", kind: "message", T: () => BoolValue },
            { no: 4, name: "render_spoilers", kind: "message", T: () => StringValue },
            { no: 5, name: "emoji_picker_collapsed_sections", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "sticker_picker_collapsed_sections", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "view_image_descriptions", kind: "message", T: () => BoolValue },
            { no: 8, name: "show_command_suggestions", kind: "message", T: () => BoolValue },
            { no: 9, name: "inline_attachment_media", kind: "message", T: () => BoolValue },
            { no: 10, name: "inline_embed_media", kind: "message", T: () => BoolValue },
            { no: 11, name: "gif_auto_play", kind: "message", T: () => BoolValue },
            { no: 12, name: "render_embeds", kind: "message", T: () => BoolValue },
            { no: 13, name: "render_reactions", kind: "message", T: () => BoolValue },
            { no: 14, name: "animate_emoji", kind: "message", T: () => BoolValue },
            { no: 15, name: "animate_stickers", kind: "message", T: () => UInt32Value },
            { no: 16, name: "enable_tts_command", kind: "message", T: () => BoolValue },
            { no: 17, name: "message_display_compact", kind: "message", T: () => BoolValue },
            { no: 19, name: "explicit_content_filter", kind: "message", T: () => UInt32Value },
            { no: 20, name: "view_nsfw_guilds", kind: "message", T: () => BoolValue },
            { no: 21, name: "convert_emoticons", kind: "message", T: () => BoolValue },
            { no: 22, name: "expression_suggestions_enabled", kind: "message", T: () => BoolValue },
            { no: 23, name: "view_nsfw_commands", kind: "message", T: () => BoolValue },
            { no: 24, name: "use_legacy_chat_input", kind: "message", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<TextAndImagesSettings>): TextAndImagesSettings {
        const message = { emojiPickerCollapsedSections: [], stickerPickerCollapsedSections: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextAndImagesSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextAndImagesSettings): TextAndImagesSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional google.protobuf.StringValue diversity_surrogate */ 1:
                    message.diversitySurrogate = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.diversitySurrogate);
                    break;
                case /* optional google.protobuf.BoolValue use_rich_chat_input */ 2:
                    message.useRichChatInput = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.useRichChatInput);
                    break;
                case /* optional google.protobuf.BoolValue use_thread_sidebar */ 3:
                    message.useThreadSidebar = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.useThreadSidebar);
                    break;
                case /* optional google.protobuf.StringValue render_spoilers */ 4:
                    message.renderSpoilers = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.renderSpoilers);
                    break;
                case /* repeated string emoji_picker_collapsed_sections */ 5:
                    message.emojiPickerCollapsedSections.push(reader.string());
                    break;
                case /* repeated string sticker_picker_collapsed_sections */ 6:
                    message.stickerPickerCollapsedSections.push(reader.string());
                    break;
                case /* optional google.protobuf.BoolValue view_image_descriptions */ 7:
                    message.viewImageDescriptions = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.viewImageDescriptions);
                    break;
                case /* optional google.protobuf.BoolValue show_command_suggestions */ 8:
                    message.showCommandSuggestions = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.showCommandSuggestions);
                    break;
                case /* optional google.protobuf.BoolValue inline_attachment_media */ 9:
                    message.inlineAttachmentMedia = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.inlineAttachmentMedia);
                    break;
                case /* optional google.protobuf.BoolValue inline_embed_media */ 10:
                    message.inlineEmbedMedia = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.inlineEmbedMedia);
                    break;
                case /* optional google.protobuf.BoolValue gif_auto_play */ 11:
                    message.gifAutoPlay = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.gifAutoPlay);
                    break;
                case /* optional google.protobuf.BoolValue render_embeds */ 12:
                    message.renderEmbeds = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.renderEmbeds);
                    break;
                case /* optional google.protobuf.BoolValue render_reactions */ 13:
                    message.renderReactions = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.renderReactions);
                    break;
                case /* optional google.protobuf.BoolValue animate_emoji */ 14:
                    message.animateEmoji = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.animateEmoji);
                    break;
                case /* optional google.protobuf.UInt32Value animate_stickers */ 15:
                    message.animateStickers = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.animateStickers);
                    break;
                case /* optional google.protobuf.BoolValue enable_tts_command */ 16:
                    message.enableTtsCommand = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.enableTtsCommand);
                    break;
                case /* optional google.protobuf.BoolValue message_display_compact */ 17:
                    message.messageDisplayCompact = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.messageDisplayCompact);
                    break;
                case /* optional google.protobuf.UInt32Value explicit_content_filter */ 19:
                    message.explicitContentFilter = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.explicitContentFilter);
                    break;
                case /* optional google.protobuf.BoolValue view_nsfw_guilds */ 20:
                    message.viewNsfwGuilds = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.viewNsfwGuilds);
                    break;
                case /* optional google.protobuf.BoolValue convert_emoticons */ 21:
                    message.convertEmoticons = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.convertEmoticons);
                    break;
                case /* optional google.protobuf.BoolValue expression_suggestions_enabled */ 22:
                    message.expressionSuggestionsEnabled = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.expressionSuggestionsEnabled);
                    break;
                case /* optional google.protobuf.BoolValue view_nsfw_commands */ 23:
                    message.viewNsfwCommands = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.viewNsfwCommands);
                    break;
                case /* optional google.protobuf.BoolValue use_legacy_chat_input */ 24:
                    message.useLegacyChatInput = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.useLegacyChatInput);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextAndImagesSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional google.protobuf.StringValue diversity_surrogate = 1; */
        if (message.diversitySurrogate)
            StringValue.internalBinaryWrite(message.diversitySurrogate, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue use_rich_chat_input = 2; */
        if (message.useRichChatInput)
            BoolValue.internalBinaryWrite(message.useRichChatInput, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue use_thread_sidebar = 3; */
        if (message.useThreadSidebar)
            BoolValue.internalBinaryWrite(message.useThreadSidebar, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.StringValue render_spoilers = 4; */
        if (message.renderSpoilers)
            StringValue.internalBinaryWrite(message.renderSpoilers, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated string emoji_picker_collapsed_sections = 5; */
        for (let i = 0; i < message.emojiPickerCollapsedSections.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.emojiPickerCollapsedSections[i]);
        /* repeated string sticker_picker_collapsed_sections = 6; */
        for (let i = 0; i < message.stickerPickerCollapsedSections.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.stickerPickerCollapsedSections[i]);
        /* optional google.protobuf.BoolValue view_image_descriptions = 7; */
        if (message.viewImageDescriptions)
            BoolValue.internalBinaryWrite(message.viewImageDescriptions, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue show_command_suggestions = 8; */
        if (message.showCommandSuggestions)
            BoolValue.internalBinaryWrite(message.showCommandSuggestions, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue inline_attachment_media = 9; */
        if (message.inlineAttachmentMedia)
            BoolValue.internalBinaryWrite(message.inlineAttachmentMedia, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue inline_embed_media = 10; */
        if (message.inlineEmbedMedia)
            BoolValue.internalBinaryWrite(message.inlineEmbedMedia, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue gif_auto_play = 11; */
        if (message.gifAutoPlay)
            BoolValue.internalBinaryWrite(message.gifAutoPlay, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue render_embeds = 12; */
        if (message.renderEmbeds)
            BoolValue.internalBinaryWrite(message.renderEmbeds, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue render_reactions = 13; */
        if (message.renderReactions)
            BoolValue.internalBinaryWrite(message.renderReactions, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue animate_emoji = 14; */
        if (message.animateEmoji)
            BoolValue.internalBinaryWrite(message.animateEmoji, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.UInt32Value animate_stickers = 15; */
        if (message.animateStickers)
            UInt32Value.internalBinaryWrite(message.animateStickers, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue enable_tts_command = 16; */
        if (message.enableTtsCommand)
            BoolValue.internalBinaryWrite(message.enableTtsCommand, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue message_display_compact = 17; */
        if (message.messageDisplayCompact)
            BoolValue.internalBinaryWrite(message.messageDisplayCompact, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.UInt32Value explicit_content_filter = 19; */
        if (message.explicitContentFilter)
            UInt32Value.internalBinaryWrite(message.explicitContentFilter, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue view_nsfw_guilds = 20; */
        if (message.viewNsfwGuilds)
            BoolValue.internalBinaryWrite(message.viewNsfwGuilds, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue convert_emoticons = 21; */
        if (message.convertEmoticons)
            BoolValue.internalBinaryWrite(message.convertEmoticons, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue expression_suggestions_enabled = 22; */
        if (message.expressionSuggestionsEnabled)
            BoolValue.internalBinaryWrite(message.expressionSuggestionsEnabled, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue view_nsfw_commands = 23; */
        if (message.viewNsfwCommands)
            BoolValue.internalBinaryWrite(message.viewNsfwCommands, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue use_legacy_chat_input = 24; */
        if (message.useLegacyChatInput)
            BoolValue.internalBinaryWrite(message.useLegacyChatInput, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.TextAndImagesSettings
 */
export const TextAndImagesSettings = new TextAndImagesSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationSettings$Type extends MessageType<NotificationSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.NotificationSettings", [
            { no: 1, name: "show_in_app_notifications", kind: "message", T: () => BoolValue },
            { no: 2, name: "notify_friends_on_go_live", kind: "message", T: () => BoolValue },
            { no: 3, name: "notification_center_acked_before_id", kind: "scalar", T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<NotificationSettings>): NotificationSettings {
        const message = { notificationCenterAckedBeforeId: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NotificationSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotificationSettings): NotificationSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional google.protobuf.BoolValue show_in_app_notifications */ 1:
                    message.showInAppNotifications = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.showInAppNotifications);
                    break;
                case /* optional google.protobuf.BoolValue notify_friends_on_go_live */ 2:
                    message.notifyFriendsOnGoLive = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.notifyFriendsOnGoLive);
                    break;
                case /* fixed64 notification_center_acked_before_id */ 3:
                    message.notificationCenterAckedBeforeId = reader.fixed64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NotificationSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional google.protobuf.BoolValue show_in_app_notifications = 1; */
        if (message.showInAppNotifications)
            BoolValue.internalBinaryWrite(message.showInAppNotifications, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue notify_friends_on_go_live = 2; */
        if (message.notifyFriendsOnGoLive)
            BoolValue.internalBinaryWrite(message.notifyFriendsOnGoLive, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* fixed64 notification_center_acked_before_id = 3; */
        if (message.notificationCenterAckedBeforeId !== 0n)
            writer.tag(3, WireType.Bit64).fixed64(message.notificationCenterAckedBeforeId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.NotificationSettings
 */
export const NotificationSettings = new NotificationSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrivacySettings$Type extends MessageType<PrivacySettings> {
    constructor() {
        super("discord_protos.discord_users.v1.PrivacySettings", [
            { no: 1, name: "allow_activity_party_privacy_friends", kind: "message", T: () => BoolValue },
            { no: 2, name: "allow_activity_party_privacy_voice_channel", kind: "message", T: () => BoolValue },
            { no: 3, name: "restricted_guild_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "default_guilds_restricted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "allow_accessibility_detection", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "detect_platform_accounts", kind: "message", T: () => BoolValue },
            { no: 9, name: "passwordless", kind: "message", T: () => BoolValue },
            { no: 10, name: "contact_sync_enabled", kind: "message", T: () => BoolValue },
            { no: 11, name: "friend_source_flags", kind: "message", T: () => UInt32Value },
            { no: 12, name: "friend_discovery_flags", kind: "message", T: () => UInt32Value },
            { no: 13, name: "activity_restricted_guild_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 14, name: "default_guilds_activity_restricted", kind: "enum", T: () => ["discord_protos.discord_users.v1.GuildActivityStatusRestrictionDefault", GuildActivityStatusRestrictionDefault] },
            { no: 15, name: "activity_joining_restricted_guild_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 16, name: "message_request_restricted_guild_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 17, name: "default_message_request_restricted", kind: "message", T: () => BoolValue },
            { no: 18, name: "drops_opted_out", kind: "message", T: () => BoolValue },
            { no: 19, name: "non_spam_retraining_opt_in", kind: "message", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<PrivacySettings>): PrivacySettings {
        const message = { restrictedGuildIds: [], defaultGuildsRestricted: false, allowAccessibilityDetection: false, activityRestrictedGuildIds: [], defaultGuildsActivityRestricted: 0, activityJoiningRestrictedGuildIds: [], messageRequestRestrictedGuildIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PrivacySettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PrivacySettings): PrivacySettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional google.protobuf.BoolValue allow_activity_party_privacy_friends */ 1:
                    message.allowActivityPartyPrivacyFriends = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.allowActivityPartyPrivacyFriends);
                    break;
                case /* optional google.protobuf.BoolValue allow_activity_party_privacy_voice_channel */ 2:
                    message.allowActivityPartyPrivacyVoiceChannel = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.allowActivityPartyPrivacyVoiceChannel);
                    break;
                case /* repeated fixed64 restricted_guild_ids */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.restrictedGuildIds.push(reader.fixed64().toBigInt());
                    else
                        message.restrictedGuildIds.push(reader.fixed64().toBigInt());
                    break;
                case /* bool default_guilds_restricted */ 4:
                    message.defaultGuildsRestricted = reader.bool();
                    break;
                case /* bool allow_accessibility_detection */ 7:
                    message.allowAccessibilityDetection = reader.bool();
                    break;
                case /* optional google.protobuf.BoolValue detect_platform_accounts */ 8:
                    message.detectPlatformAccounts = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.detectPlatformAccounts);
                    break;
                case /* optional google.protobuf.BoolValue passwordless */ 9:
                    message.passwordless = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.passwordless);
                    break;
                case /* optional google.protobuf.BoolValue contact_sync_enabled */ 10:
                    message.contactSyncEnabled = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.contactSyncEnabled);
                    break;
                case /* optional google.protobuf.UInt32Value friend_source_flags */ 11:
                    message.friendSourceFlags = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.friendSourceFlags);
                    break;
                case /* optional google.protobuf.UInt32Value friend_discovery_flags */ 12:
                    message.friendDiscoveryFlags = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.friendDiscoveryFlags);
                    break;
                case /* repeated fixed64 activity_restricted_guild_ids */ 13:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.activityRestrictedGuildIds.push(reader.fixed64().toBigInt());
                    else
                        message.activityRestrictedGuildIds.push(reader.fixed64().toBigInt());
                    break;
                case /* discord_protos.discord_users.v1.GuildActivityStatusRestrictionDefault default_guilds_activity_restricted */ 14:
                    message.defaultGuildsActivityRestricted = reader.int32();
                    break;
                case /* repeated fixed64 activity_joining_restricted_guild_ids */ 15:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.activityJoiningRestrictedGuildIds.push(reader.fixed64().toBigInt());
                    else
                        message.activityJoiningRestrictedGuildIds.push(reader.fixed64().toBigInt());
                    break;
                case /* repeated fixed64 message_request_restricted_guild_ids */ 16:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.messageRequestRestrictedGuildIds.push(reader.fixed64().toBigInt());
                    else
                        message.messageRequestRestrictedGuildIds.push(reader.fixed64().toBigInt());
                    break;
                case /* optional google.protobuf.BoolValue default_message_request_restricted */ 17:
                    message.defaultMessageRequestRestricted = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.defaultMessageRequestRestricted);
                    break;
                case /* optional google.protobuf.BoolValue drops_opted_out */ 18:
                    message.dropsOptedOut = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.dropsOptedOut);
                    break;
                case /* optional google.protobuf.BoolValue non_spam_retraining_opt_in */ 19:
                    message.nonSpamRetrainingOptIn = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.nonSpamRetrainingOptIn);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PrivacySettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional google.protobuf.BoolValue allow_activity_party_privacy_friends = 1; */
        if (message.allowActivityPartyPrivacyFriends)
            BoolValue.internalBinaryWrite(message.allowActivityPartyPrivacyFriends, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue allow_activity_party_privacy_voice_channel = 2; */
        if (message.allowActivityPartyPrivacyVoiceChannel)
            BoolValue.internalBinaryWrite(message.allowActivityPartyPrivacyVoiceChannel, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated fixed64 restricted_guild_ids = 3; */
        if (message.restrictedGuildIds.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.restrictedGuildIds.length; i++)
                writer.fixed64(message.restrictedGuildIds[i]);
            writer.join();
        }
        /* bool default_guilds_restricted = 4; */
        if (message.defaultGuildsRestricted !== false)
            writer.tag(4, WireType.Varint).bool(message.defaultGuildsRestricted);
        /* bool allow_accessibility_detection = 7; */
        if (message.allowAccessibilityDetection !== false)
            writer.tag(7, WireType.Varint).bool(message.allowAccessibilityDetection);
        /* optional google.protobuf.BoolValue detect_platform_accounts = 8; */
        if (message.detectPlatformAccounts)
            BoolValue.internalBinaryWrite(message.detectPlatformAccounts, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue passwordless = 9; */
        if (message.passwordless)
            BoolValue.internalBinaryWrite(message.passwordless, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue contact_sync_enabled = 10; */
        if (message.contactSyncEnabled)
            BoolValue.internalBinaryWrite(message.contactSyncEnabled, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.UInt32Value friend_source_flags = 11; */
        if (message.friendSourceFlags)
            UInt32Value.internalBinaryWrite(message.friendSourceFlags, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.UInt32Value friend_discovery_flags = 12; */
        if (message.friendDiscoveryFlags)
            UInt32Value.internalBinaryWrite(message.friendDiscoveryFlags, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* repeated fixed64 activity_restricted_guild_ids = 13; */
        if (message.activityRestrictedGuildIds.length) {
            writer.tag(13, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.activityRestrictedGuildIds.length; i++)
                writer.fixed64(message.activityRestrictedGuildIds[i]);
            writer.join();
        }
        /* discord_protos.discord_users.v1.GuildActivityStatusRestrictionDefault default_guilds_activity_restricted = 14; */
        if (message.defaultGuildsActivityRestricted !== 0)
            writer.tag(14, WireType.Varint).int32(message.defaultGuildsActivityRestricted);
        /* repeated fixed64 activity_joining_restricted_guild_ids = 15; */
        if (message.activityJoiningRestrictedGuildIds.length) {
            writer.tag(15, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.activityJoiningRestrictedGuildIds.length; i++)
                writer.fixed64(message.activityJoiningRestrictedGuildIds[i]);
            writer.join();
        }
        /* repeated fixed64 message_request_restricted_guild_ids = 16; */
        if (message.messageRequestRestrictedGuildIds.length) {
            writer.tag(16, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.messageRequestRestrictedGuildIds.length; i++)
                writer.fixed64(message.messageRequestRestrictedGuildIds[i]);
            writer.join();
        }
        /* optional google.protobuf.BoolValue default_message_request_restricted = 17; */
        if (message.defaultMessageRequestRestricted)
            BoolValue.internalBinaryWrite(message.defaultMessageRequestRestricted, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue drops_opted_out = 18; */
        if (message.dropsOptedOut)
            BoolValue.internalBinaryWrite(message.dropsOptedOut, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue non_spam_retraining_opt_in = 19; */
        if (message.nonSpamRetrainingOptIn)
            BoolValue.internalBinaryWrite(message.nonSpamRetrainingOptIn, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.PrivacySettings
 */
export const PrivacySettings = new PrivacySettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DebugSettings$Type extends MessageType<DebugSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.DebugSettings", [
            { no: 1, name: "rtc_panel_show_voice_states", kind: "message", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<DebugSettings>): DebugSettings {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DebugSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DebugSettings): DebugSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional google.protobuf.BoolValue rtc_panel_show_voice_states */ 1:
                    message.rtcPanelShowVoiceStates = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.rtcPanelShowVoiceStates);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DebugSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional google.protobuf.BoolValue rtc_panel_show_voice_states = 1; */
        if (message.rtcPanelShowVoiceStates)
            BoolValue.internalBinaryWrite(message.rtcPanelShowVoiceStates, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.DebugSettings
 */
export const DebugSettings = new DebugSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GameLibrarySettings$Type extends MessageType<GameLibrarySettings> {
    constructor() {
        super("discord_protos.discord_users.v1.GameLibrarySettings", [
            { no: 1, name: "install_shortcut_desktop", kind: "message", T: () => BoolValue },
            { no: 2, name: "install_shortcut_start_menu", kind: "message", T: () => BoolValue },
            { no: 3, name: "disable_games_tab", kind: "message", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<GameLibrarySettings>): GameLibrarySettings {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GameLibrarySettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GameLibrarySettings): GameLibrarySettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional google.protobuf.BoolValue install_shortcut_desktop */ 1:
                    message.installShortcutDesktop = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.installShortcutDesktop);
                    break;
                case /* optional google.protobuf.BoolValue install_shortcut_start_menu */ 2:
                    message.installShortcutStartMenu = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.installShortcutStartMenu);
                    break;
                case /* optional google.protobuf.BoolValue disable_games_tab */ 3:
                    message.disableGamesTab = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.disableGamesTab);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GameLibrarySettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional google.protobuf.BoolValue install_shortcut_desktop = 1; */
        if (message.installShortcutDesktop)
            BoolValue.internalBinaryWrite(message.installShortcutDesktop, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue install_shortcut_start_menu = 2; */
        if (message.installShortcutStartMenu)
            BoolValue.internalBinaryWrite(message.installShortcutStartMenu, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue disable_games_tab = 3; */
        if (message.disableGamesTab)
            BoolValue.internalBinaryWrite(message.disableGamesTab, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.GameLibrarySettings
 */
export const GameLibrarySettings = new GameLibrarySettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomStatus$Type extends MessageType<CustomStatus> {
    constructor() {
        super("discord_protos.discord_users.v1.CustomStatus", [
            { no: 1, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "emoji_id", kind: "scalar", T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "emoji_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "expires_at_ms", kind: "scalar", T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<CustomStatus>): CustomStatus {
        const message = { text: "", emojiId: 0n, emojiName: "", expiresAtMs: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomStatus): CustomStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string text */ 1:
                    message.text = reader.string();
                    break;
                case /* fixed64 emoji_id */ 2:
                    message.emojiId = reader.fixed64().toBigInt();
                    break;
                case /* string emoji_name */ 3:
                    message.emojiName = reader.string();
                    break;
                case /* fixed64 expires_at_ms */ 4:
                    message.expiresAtMs = reader.fixed64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string text = 1; */
        if (message.text !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.text);
        /* fixed64 emoji_id = 2; */
        if (message.emojiId !== 0n)
            writer.tag(2, WireType.Bit64).fixed64(message.emojiId);
        /* string emoji_name = 3; */
        if (message.emojiName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.emojiName);
        /* fixed64 expires_at_ms = 4; */
        if (message.expiresAtMs !== 0n)
            writer.tag(4, WireType.Bit64).fixed64(message.expiresAtMs);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.CustomStatus
 */
export const CustomStatus = new CustomStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StatusSettings$Type extends MessageType<StatusSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.StatusSettings", [
            { no: 1, name: "status", kind: "message", T: () => StringValue },
            { no: 2, name: "custom_status", kind: "message", T: () => CustomStatus },
            { no: 3, name: "show_current_game", kind: "message", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<StatusSettings>): StatusSettings {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StatusSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StatusSettings): StatusSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional google.protobuf.StringValue status */ 1:
                    message.status = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* optional discord_protos.discord_users.v1.CustomStatus custom_status */ 2:
                    message.customStatus = CustomStatus.internalBinaryRead(reader, reader.uint32(), options, message.customStatus);
                    break;
                case /* optional google.protobuf.BoolValue show_current_game */ 3:
                    message.showCurrentGame = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.showCurrentGame);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StatusSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional google.protobuf.StringValue status = 1; */
        if (message.status)
            StringValue.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.CustomStatus custom_status = 2; */
        if (message.customStatus)
            CustomStatus.internalBinaryWrite(message.customStatus, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.BoolValue show_current_game = 3; */
        if (message.showCurrentGame)
            BoolValue.internalBinaryWrite(message.showCurrentGame, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.StatusSettings
 */
export const StatusSettings = new StatusSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocalizationSettings$Type extends MessageType<LocalizationSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.LocalizationSettings", [
            { no: 1, name: "locale", kind: "message", T: () => StringValue },
            { no: 2, name: "timezone_offset", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<LocalizationSettings>): LocalizationSettings {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocalizationSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocalizationSettings): LocalizationSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional google.protobuf.StringValue locale */ 1:
                    message.locale = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.locale);
                    break;
                case /* optional google.protobuf.Int32Value timezone_offset */ 2:
                    message.timezoneOffset = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.timezoneOffset);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocalizationSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional google.protobuf.StringValue locale = 1; */
        if (message.locale)
            StringValue.internalBinaryWrite(message.locale, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.Int32Value timezone_offset = 2; */
        if (message.timezoneOffset)
            Int32Value.internalBinaryWrite(message.timezoneOffset, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.LocalizationSettings
 */
export const LocalizationSettings = new LocalizationSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClientThemeSettings$Type extends MessageType<ClientThemeSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.ClientThemeSettings", [
            { no: 1, name: "primary_color", kind: "message", T: () => UInt64Value },
            { no: 2, name: "background_gradient_preset_id", kind: "message", T: () => UInt32Value },
            { no: 3, name: "background_gradient_angle", kind: "message", T: () => FloatValue }
        ]);
    }
    create(value?: PartialMessage<ClientThemeSettings>): ClientThemeSettings {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClientThemeSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClientThemeSettings): ClientThemeSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional google.protobuf.UInt64Value primary_color */ 1:
                    message.primaryColor = UInt64Value.internalBinaryRead(reader, reader.uint32(), options, message.primaryColor);
                    break;
                case /* optional google.protobuf.UInt32Value background_gradient_preset_id */ 2:
                    message.backgroundGradientPresetId = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.backgroundGradientPresetId);
                    break;
                case /* optional google.protobuf.FloatValue background_gradient_angle */ 3:
                    message.backgroundGradientAngle = FloatValue.internalBinaryRead(reader, reader.uint32(), options, message.backgroundGradientAngle);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClientThemeSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional google.protobuf.UInt64Value primary_color = 1; */
        if (message.primaryColor)
            UInt64Value.internalBinaryWrite(message.primaryColor, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.UInt32Value background_gradient_preset_id = 2; */
        if (message.backgroundGradientPresetId)
            UInt32Value.internalBinaryWrite(message.backgroundGradientPresetId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.FloatValue background_gradient_angle = 3; */
        if (message.backgroundGradientAngle)
            FloatValue.internalBinaryWrite(message.backgroundGradientAngle, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.ClientThemeSettings
 */
export const ClientThemeSettings = new ClientThemeSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppearanceSettings$Type extends MessageType<AppearanceSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.AppearanceSettings", [
            { no: 1, name: "theme", kind: "enum", T: () => ["discord_protos.discord_users.v1.Theme", Theme] },
            { no: 2, name: "developer_mode", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "client_theme_settings", kind: "message", T: () => ClientThemeSettings },
            { no: 4, name: "mobile_redesign_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AppearanceSettings>): AppearanceSettings {
        const message = { theme: 0, developerMode: false, mobileRedesignDisabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppearanceSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppearanceSettings): AppearanceSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* discord_protos.discord_users.v1.Theme theme */ 1:
                    message.theme = reader.int32();
                    break;
                case /* bool developer_mode */ 2:
                    message.developerMode = reader.bool();
                    break;
                case /* optional discord_protos.discord_users.v1.ClientThemeSettings client_theme_settings */ 3:
                    message.clientThemeSettings = ClientThemeSettings.internalBinaryRead(reader, reader.uint32(), options, message.clientThemeSettings);
                    break;
                case /* bool mobile_redesign_disabled */ 4:
                    message.mobileRedesignDisabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppearanceSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* discord_protos.discord_users.v1.Theme theme = 1; */
        if (message.theme !== 0)
            writer.tag(1, WireType.Varint).int32(message.theme);
        /* bool developer_mode = 2; */
        if (message.developerMode !== false)
            writer.tag(2, WireType.Varint).bool(message.developerMode);
        /* optional discord_protos.discord_users.v1.ClientThemeSettings client_theme_settings = 3; */
        if (message.clientThemeSettings)
            ClientThemeSettings.internalBinaryWrite(message.clientThemeSettings, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool mobile_redesign_disabled = 4; */
        if (message.mobileRedesignDisabled !== false)
            writer.tag(4, WireType.Varint).bool(message.mobileRedesignDisabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.AppearanceSettings
 */
export const AppearanceSettings = new AppearanceSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuildFolder$Type extends MessageType<GuildFolder> {
    constructor() {
        super("discord_protos.discord_users.v1.GuildFolder", [
            { no: 1, name: "guild_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "id", kind: "message", T: () => Int64Value },
            { no: 3, name: "name", kind: "message", T: () => StringValue },
            { no: 4, name: "color", kind: "message", T: () => UInt64Value }
        ]);
    }
    create(value?: PartialMessage<GuildFolder>): GuildFolder {
        const message = { guildIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuildFolder>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuildFolder): GuildFolder {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated fixed64 guild_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.guildIds.push(reader.fixed64().toBigInt());
                    else
                        message.guildIds.push(reader.fixed64().toBigInt());
                    break;
                case /* optional google.protobuf.Int64Value id */ 2:
                    message.id = Int64Value.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* optional google.protobuf.StringValue name */ 3:
                    message.name = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.name);
                    break;
                case /* optional google.protobuf.UInt64Value color */ 4:
                    message.color = UInt64Value.internalBinaryRead(reader, reader.uint32(), options, message.color);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GuildFolder, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated fixed64 guild_ids = 1; */
        if (message.guildIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.guildIds.length; i++)
                writer.fixed64(message.guildIds[i]);
            writer.join();
        }
        /* optional google.protobuf.Int64Value id = 2; */
        if (message.id)
            Int64Value.internalBinaryWrite(message.id, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.StringValue name = 3; */
        if (message.name)
            StringValue.internalBinaryWrite(message.name, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.UInt64Value color = 4; */
        if (message.color)
            UInt64Value.internalBinaryWrite(message.color, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.GuildFolder
 */
export const GuildFolder = new GuildFolder$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuildFolders$Type extends MessageType<GuildFolders> {
    constructor() {
        super("discord_protos.discord_users.v1.GuildFolders", [
            { no: 1, name: "folders", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GuildFolder },
            { no: 2, name: "guild_positions", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<GuildFolders>): GuildFolders {
        const message = { folders: [], guildPositions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GuildFolders>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuildFolders): GuildFolders {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated discord_protos.discord_users.v1.GuildFolder folders */ 1:
                    message.folders.push(GuildFolder.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated fixed64 guild_positions */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.guildPositions.push(reader.fixed64().toBigInt());
                    else
                        message.guildPositions.push(reader.fixed64().toBigInt());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GuildFolders, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated discord_protos.discord_users.v1.GuildFolder folders = 1; */
        for (let i = 0; i < message.folders.length; i++)
            GuildFolder.internalBinaryWrite(message.folders[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated fixed64 guild_positions = 2; */
        if (message.guildPositions.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.guildPositions.length; i++)
                writer.fixed64(message.guildPositions[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.GuildFolders
 */
export const GuildFolders = new GuildFolders$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FavoriteChannel$Type extends MessageType<FavoriteChannel> {
    constructor() {
        super("discord_protos.discord_users.v1.FavoriteChannel", [
            { no: 1, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["discord_protos.discord_users.v1.FavoriteChannelType", FavoriteChannelType] },
            { no: 3, name: "position", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "parent_id", kind: "scalar", T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<FavoriteChannel>): FavoriteChannel {
        const message = { nickname: "", type: 0, position: 0, parentId: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FavoriteChannel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FavoriteChannel): FavoriteChannel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string nickname */ 1:
                    message.nickname = reader.string();
                    break;
                case /* discord_protos.discord_users.v1.FavoriteChannelType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* uint32 position */ 3:
                    message.position = reader.uint32();
                    break;
                case /* fixed64 parent_id */ 4:
                    message.parentId = reader.fixed64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FavoriteChannel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string nickname = 1; */
        if (message.nickname !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nickname);
        /* discord_protos.discord_users.v1.FavoriteChannelType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* uint32 position = 3; */
        if (message.position !== 0)
            writer.tag(3, WireType.Varint).uint32(message.position);
        /* fixed64 parent_id = 4; */
        if (message.parentId !== 0n)
            writer.tag(4, WireType.Bit64).fixed64(message.parentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.FavoriteChannel
 */
export const FavoriteChannel = new FavoriteChannel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Favorites$Type extends MessageType<Favorites> {
    constructor() {
        super("discord_protos.discord_users.v1.Favorites", [
            { no: 1, name: "favorite_channels", kind: "map", K: 6 /*ScalarType.FIXED64*/, V: { kind: "message", T: () => FavoriteChannel } },
            { no: 2, name: "muted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Favorites>): Favorites {
        const message = { favoriteChannels: {}, muted: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Favorites>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Favorites): Favorites {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<fixed64, discord_protos.discord_users.v1.FavoriteChannel> favorite_channels */ 1:
                    this.binaryReadMap1(message.favoriteChannels, reader, options);
                    break;
                case /* bool muted */ 2:
                    message.muted = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: Favorites["favoriteChannels"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Favorites["favoriteChannels"] | undefined, val: Favorites["favoriteChannels"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.fixed64().toString();
                    break;
                case 2:
                    val = FavoriteChannel.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field discord_protos.discord_users.v1.Favorites.favorite_channels");
            }
        }
        map[key ?? "0"] = val ?? FavoriteChannel.create();
    }
    internalBinaryWrite(message: Favorites, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<fixed64, discord_protos.discord_users.v1.FavoriteChannel> favorite_channels = 1; */
        for (let k of Object.keys(message.favoriteChannels)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Bit64).fixed64(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            FavoriteChannel.internalBinaryWrite(message.favoriteChannels[k], writer, options);
            writer.join().join();
        }
        /* bool muted = 2; */
        if (message.muted !== false)
            writer.tag(2, WireType.Varint).bool(message.muted);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.Favorites
 */
export const Favorites = new Favorites$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AudioContextSetting$Type extends MessageType<AudioContextSetting> {
    constructor() {
        super("discord_protos.discord_users.v1.AudioContextSetting", [
            { no: 1, name: "muted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "volume", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "modified_at", kind: "scalar", T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<AudioContextSetting>): AudioContextSetting {
        const message = { muted: false, volume: 0, modifiedAt: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AudioContextSetting>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AudioContextSetting): AudioContextSetting {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool muted */ 1:
                    message.muted = reader.bool();
                    break;
                case /* float volume */ 2:
                    message.volume = reader.float();
                    break;
                case /* fixed64 modified_at */ 3:
                    message.modifiedAt = reader.fixed64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AudioContextSetting, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool muted = 1; */
        if (message.muted !== false)
            writer.tag(1, WireType.Varint).bool(message.muted);
        /* float volume = 2; */
        if (message.volume !== 0)
            writer.tag(2, WireType.Bit32).float(message.volume);
        /* fixed64 modified_at = 3; */
        if (message.modifiedAt !== 0n)
            writer.tag(3, WireType.Bit64).fixed64(message.modifiedAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.AudioContextSetting
 */
export const AudioContextSetting = new AudioContextSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AudioSettings$Type extends MessageType<AudioSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.AudioSettings", [
            { no: 1, name: "user", kind: "map", K: 6 /*ScalarType.FIXED64*/, V: { kind: "message", T: () => AudioContextSetting } },
            { no: 2, name: "stream", kind: "map", K: 6 /*ScalarType.FIXED64*/, V: { kind: "message", T: () => AudioContextSetting } }
        ]);
    }
    create(value?: PartialMessage<AudioSettings>): AudioSettings {
        const message = { user: {}, stream: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AudioSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AudioSettings): AudioSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<fixed64, discord_protos.discord_users.v1.AudioContextSetting> user */ 1:
                    this.binaryReadMap1(message.user, reader, options);
                    break;
                case /* map<fixed64, discord_protos.discord_users.v1.AudioContextSetting> stream */ 2:
                    this.binaryReadMap2(message.stream, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: AudioSettings["user"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AudioSettings["user"] | undefined, val: AudioSettings["user"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.fixed64().toString();
                    break;
                case 2:
                    val = AudioContextSetting.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field discord_protos.discord_users.v1.AudioSettings.user");
            }
        }
        map[key ?? "0"] = val ?? AudioContextSetting.create();
    }
    private binaryReadMap2(map: AudioSettings["stream"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AudioSettings["stream"] | undefined, val: AudioSettings["stream"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.fixed64().toString();
                    break;
                case 2:
                    val = AudioContextSetting.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field discord_protos.discord_users.v1.AudioSettings.stream");
            }
        }
        map[key ?? "0"] = val ?? AudioContextSetting.create();
    }
    internalBinaryWrite(message: AudioSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<fixed64, discord_protos.discord_users.v1.AudioContextSetting> user = 1; */
        for (let k of Object.keys(message.user)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Bit64).fixed64(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            AudioContextSetting.internalBinaryWrite(message.user[k], writer, options);
            writer.join().join();
        }
        /* map<fixed64, discord_protos.discord_users.v1.AudioContextSetting> stream = 2; */
        for (let k of Object.keys(message.stream)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.Bit64).fixed64(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            AudioContextSetting.internalBinaryWrite(message.stream[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.AudioSettings
 */
export const AudioSettings = new AudioSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CommunitiesSettings$Type extends MessageType<CommunitiesSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.CommunitiesSettings", [
            { no: 1, name: "disable_home_auto_nav", kind: "message", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<CommunitiesSettings>): CommunitiesSettings {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CommunitiesSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CommunitiesSettings): CommunitiesSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional google.protobuf.BoolValue disable_home_auto_nav */ 1:
                    message.disableHomeAutoNav = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.disableHomeAutoNav);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CommunitiesSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional google.protobuf.BoolValue disable_home_auto_nav = 1; */
        if (message.disableHomeAutoNav)
            BoolValue.internalBinaryWrite(message.disableHomeAutoNav, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.CommunitiesSettings
 */
export const CommunitiesSettings = new CommunitiesSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreloadedUserSettings$Type extends MessageType<PreloadedUserSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.PreloadedUserSettings", [
            { no: 1, name: "versions", kind: "message", T: () => Versions },
            { no: 2, name: "inbox", kind: "message", T: () => InboxSettings },
            { no: 3, name: "guilds", kind: "message", T: () => AllGuildSettings },
            { no: 4, name: "user_content", kind: "message", T: () => UserContentSettings },
            { no: 5, name: "voice_and_video", kind: "message", T: () => VoiceAndVideoSettings },
            { no: 6, name: "text_and_images", kind: "message", T: () => TextAndImagesSettings },
            { no: 7, name: "notifications", kind: "message", T: () => NotificationSettings },
            { no: 8, name: "privacy", kind: "message", T: () => PrivacySettings },
            { no: 9, name: "debug", kind: "message", T: () => DebugSettings },
            { no: 10, name: "game_library", kind: "message", T: () => GameLibrarySettings },
            { no: 11, name: "status", kind: "message", T: () => StatusSettings },
            { no: 12, name: "localization", kind: "message", T: () => LocalizationSettings },
            { no: 13, name: "appearance", kind: "message", T: () => AppearanceSettings },
            { no: 14, name: "guild_folders", kind: "message", T: () => GuildFolders },
            { no: 15, name: "favorites", kind: "message", T: () => Favorites },
            { no: 16, name: "audio_context_settings", kind: "message", T: () => AudioSettings },
            { no: 17, name: "communities", kind: "message", T: () => CommunitiesSettings }
        ]);
    }
    create(value?: PartialMessage<PreloadedUserSettings>): PreloadedUserSettings {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PreloadedUserSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PreloadedUserSettings): PreloadedUserSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional discord_protos.discord_users.v1.Versions versions */ 1:
                    message.versions = Versions.internalBinaryRead(reader, reader.uint32(), options, message.versions);
                    break;
                case /* optional discord_protos.discord_users.v1.InboxSettings inbox */ 2:
                    message.inbox = InboxSettings.internalBinaryRead(reader, reader.uint32(), options, message.inbox);
                    break;
                case /* optional discord_protos.discord_users.v1.AllGuildSettings guilds */ 3:
                    message.guilds = AllGuildSettings.internalBinaryRead(reader, reader.uint32(), options, message.guilds);
                    break;
                case /* optional discord_protos.discord_users.v1.UserContentSettings user_content */ 4:
                    message.userContent = UserContentSettings.internalBinaryRead(reader, reader.uint32(), options, message.userContent);
                    break;
                case /* optional discord_protos.discord_users.v1.VoiceAndVideoSettings voice_and_video */ 5:
                    message.voiceAndVideo = VoiceAndVideoSettings.internalBinaryRead(reader, reader.uint32(), options, message.voiceAndVideo);
                    break;
                case /* optional discord_protos.discord_users.v1.TextAndImagesSettings text_and_images */ 6:
                    message.textAndImages = TextAndImagesSettings.internalBinaryRead(reader, reader.uint32(), options, message.textAndImages);
                    break;
                case /* optional discord_protos.discord_users.v1.NotificationSettings notifications */ 7:
                    message.notifications = NotificationSettings.internalBinaryRead(reader, reader.uint32(), options, message.notifications);
                    break;
                case /* optional discord_protos.discord_users.v1.PrivacySettings privacy */ 8:
                    message.privacy = PrivacySettings.internalBinaryRead(reader, reader.uint32(), options, message.privacy);
                    break;
                case /* optional discord_protos.discord_users.v1.DebugSettings debug */ 9:
                    message.debug = DebugSettings.internalBinaryRead(reader, reader.uint32(), options, message.debug);
                    break;
                case /* optional discord_protos.discord_users.v1.GameLibrarySettings game_library */ 10:
                    message.gameLibrary = GameLibrarySettings.internalBinaryRead(reader, reader.uint32(), options, message.gameLibrary);
                    break;
                case /* optional discord_protos.discord_users.v1.StatusSettings status */ 11:
                    message.status = StatusSettings.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* optional discord_protos.discord_users.v1.LocalizationSettings localization */ 12:
                    message.localization = LocalizationSettings.internalBinaryRead(reader, reader.uint32(), options, message.localization);
                    break;
                case /* optional discord_protos.discord_users.v1.AppearanceSettings appearance */ 13:
                    message.appearance = AppearanceSettings.internalBinaryRead(reader, reader.uint32(), options, message.appearance);
                    break;
                case /* optional discord_protos.discord_users.v1.GuildFolders guild_folders */ 14:
                    message.guildFolders = GuildFolders.internalBinaryRead(reader, reader.uint32(), options, message.guildFolders);
                    break;
                case /* optional discord_protos.discord_users.v1.Favorites favorites */ 15:
                    message.favorites = Favorites.internalBinaryRead(reader, reader.uint32(), options, message.favorites);
                    break;
                case /* optional discord_protos.discord_users.v1.AudioSettings audio_context_settings */ 16:
                    message.audioContextSettings = AudioSettings.internalBinaryRead(reader, reader.uint32(), options, message.audioContextSettings);
                    break;
                case /* optional discord_protos.discord_users.v1.CommunitiesSettings communities */ 17:
                    message.communities = CommunitiesSettings.internalBinaryRead(reader, reader.uint32(), options, message.communities);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PreloadedUserSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional discord_protos.discord_users.v1.Versions versions = 1; */
        if (message.versions)
            Versions.internalBinaryWrite(message.versions, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.InboxSettings inbox = 2; */
        if (message.inbox)
            InboxSettings.internalBinaryWrite(message.inbox, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.AllGuildSettings guilds = 3; */
        if (message.guilds)
            AllGuildSettings.internalBinaryWrite(message.guilds, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.UserContentSettings user_content = 4; */
        if (message.userContent)
            UserContentSettings.internalBinaryWrite(message.userContent, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.VoiceAndVideoSettings voice_and_video = 5; */
        if (message.voiceAndVideo)
            VoiceAndVideoSettings.internalBinaryWrite(message.voiceAndVideo, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.TextAndImagesSettings text_and_images = 6; */
        if (message.textAndImages)
            TextAndImagesSettings.internalBinaryWrite(message.textAndImages, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.NotificationSettings notifications = 7; */
        if (message.notifications)
            NotificationSettings.internalBinaryWrite(message.notifications, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.PrivacySettings privacy = 8; */
        if (message.privacy)
            PrivacySettings.internalBinaryWrite(message.privacy, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.DebugSettings debug = 9; */
        if (message.debug)
            DebugSettings.internalBinaryWrite(message.debug, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.GameLibrarySettings game_library = 10; */
        if (message.gameLibrary)
            GameLibrarySettings.internalBinaryWrite(message.gameLibrary, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.StatusSettings status = 11; */
        if (message.status)
            StatusSettings.internalBinaryWrite(message.status, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.LocalizationSettings localization = 12; */
        if (message.localization)
            LocalizationSettings.internalBinaryWrite(message.localization, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.AppearanceSettings appearance = 13; */
        if (message.appearance)
            AppearanceSettings.internalBinaryWrite(message.appearance, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.GuildFolders guild_folders = 14; */
        if (message.guildFolders)
            GuildFolders.internalBinaryWrite(message.guildFolders, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.Favorites favorites = 15; */
        if (message.favorites)
            Favorites.internalBinaryWrite(message.favorites, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.AudioSettings audio_context_settings = 16; */
        if (message.audioContextSettings)
            AudioSettings.internalBinaryWrite(message.audioContextSettings, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.CommunitiesSettings communities = 17; */
        if (message.communities)
            CommunitiesSettings.internalBinaryWrite(message.communities, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.PreloadedUserSettings
 */
export const PreloadedUserSettings = new PreloadedUserSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FavoriteGIF$Type extends MessageType<FavoriteGIF> {
    constructor() {
        super("discord_protos.discord_users.v1.FavoriteGIF", [
            { no: 1, name: "format", kind: "enum", T: () => ["discord_protos.discord_users.v1.GIFType", GIFType] },
            { no: 2, name: "src", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "width", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "height", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "order", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<FavoriteGIF>): FavoriteGIF {
        const message = { format: 0, src: "", width: 0, height: 0, order: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FavoriteGIF>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FavoriteGIF): FavoriteGIF {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* discord_protos.discord_users.v1.GIFType format */ 1:
                    message.format = reader.int32();
                    break;
                case /* string src */ 2:
                    message.src = reader.string();
                    break;
                case /* uint32 width */ 3:
                    message.width = reader.uint32();
                    break;
                case /* uint32 height */ 4:
                    message.height = reader.uint32();
                    break;
                case /* uint32 order */ 5:
                    message.order = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FavoriteGIF, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* discord_protos.discord_users.v1.GIFType format = 1; */
        if (message.format !== 0)
            writer.tag(1, WireType.Varint).int32(message.format);
        /* string src = 2; */
        if (message.src !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.src);
        /* uint32 width = 3; */
        if (message.width !== 0)
            writer.tag(3, WireType.Varint).uint32(message.width);
        /* uint32 height = 4; */
        if (message.height !== 0)
            writer.tag(4, WireType.Varint).uint32(message.height);
        /* uint32 order = 5; */
        if (message.order !== 0)
            writer.tag(5, WireType.Varint).uint32(message.order);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.FavoriteGIF
 */
export const FavoriteGIF = new FavoriteGIF$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FavoriteGIFs$Type extends MessageType<FavoriteGIFs> {
    constructor() {
        super("discord_protos.discord_users.v1.FavoriteGIFs", [
            { no: 1, name: "gifs", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => FavoriteGIF } },
            { no: 2, name: "hide_tooltip", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FavoriteGIFs>): FavoriteGIFs {
        const message = { gifs: {}, hideTooltip: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FavoriteGIFs>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FavoriteGIFs): FavoriteGIFs {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, discord_protos.discord_users.v1.FavoriteGIF> gifs */ 1:
                    this.binaryReadMap1(message.gifs, reader, options);
                    break;
                case /* bool hide_tooltip */ 2:
                    message.hideTooltip = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: FavoriteGIFs["gifs"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof FavoriteGIFs["gifs"] | undefined, val: FavoriteGIFs["gifs"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = FavoriteGIF.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field discord_protos.discord_users.v1.FavoriteGIFs.gifs");
            }
        }
        map[key ?? ""] = val ?? FavoriteGIF.create();
    }
    internalBinaryWrite(message: FavoriteGIFs, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, discord_protos.discord_users.v1.FavoriteGIF> gifs = 1; */
        for (let k of Object.keys(message.gifs)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            FavoriteGIF.internalBinaryWrite(message.gifs[k], writer, options);
            writer.join().join();
        }
        /* bool hide_tooltip = 2; */
        if (message.hideTooltip !== false)
            writer.tag(2, WireType.Varint).bool(message.hideTooltip);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.FavoriteGIFs
 */
export const FavoriteGIFs = new FavoriteGIFs$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FavoriteStickers$Type extends MessageType<FavoriteStickers> {
    constructor() {
        super("discord_protos.discord_users.v1.FavoriteStickers", [
            { no: 1, name: "sticker_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<FavoriteStickers>): FavoriteStickers {
        const message = { stickerIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FavoriteStickers>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FavoriteStickers): FavoriteStickers {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated fixed64 sticker_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.stickerIds.push(reader.fixed64().toBigInt());
                    else
                        message.stickerIds.push(reader.fixed64().toBigInt());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FavoriteStickers, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated fixed64 sticker_ids = 1; */
        if (message.stickerIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.stickerIds.length; i++)
                writer.fixed64(message.stickerIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.FavoriteStickers
 */
export const FavoriteStickers = new FavoriteStickers$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FrecencyItem$Type extends MessageType<FrecencyItem> {
    constructor() {
        super("discord_protos.discord_users.v1.FrecencyItem", [
            { no: 1, name: "total_uses", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "recent_uses", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "frecency", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "score", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<FrecencyItem>): FrecencyItem {
        const message = { totalUses: 0, recentUses: [], frecency: 0, score: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FrecencyItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FrecencyItem): FrecencyItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 total_uses */ 1:
                    message.totalUses = reader.uint32();
                    break;
                case /* repeated uint64 recent_uses */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.recentUses.push(reader.uint64().toBigInt());
                    else
                        message.recentUses.push(reader.uint64().toBigInt());
                    break;
                case /* int32 frecency */ 3:
                    message.frecency = reader.int32();
                    break;
                case /* int32 score */ 4:
                    message.score = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FrecencyItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 total_uses = 1; */
        if (message.totalUses !== 0)
            writer.tag(1, WireType.Varint).uint32(message.totalUses);
        /* repeated uint64 recent_uses = 2; */
        if (message.recentUses.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.recentUses.length; i++)
                writer.uint64(message.recentUses[i]);
            writer.join();
        }
        /* int32 frecency = 3; */
        if (message.frecency !== 0)
            writer.tag(3, WireType.Varint).int32(message.frecency);
        /* int32 score = 4; */
        if (message.score !== 0)
            writer.tag(4, WireType.Varint).int32(message.score);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.FrecencyItem
 */
export const FrecencyItem = new FrecencyItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StickerFrecency$Type extends MessageType<StickerFrecency> {
    constructor() {
        super("discord_protos.discord_users.v1.StickerFrecency", [
            { no: 1, name: "stickers", kind: "map", K: 6 /*ScalarType.FIXED64*/, V: { kind: "message", T: () => FrecencyItem } }
        ]);
    }
    create(value?: PartialMessage<StickerFrecency>): StickerFrecency {
        const message = { stickers: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StickerFrecency>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StickerFrecency): StickerFrecency {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<fixed64, discord_protos.discord_users.v1.FrecencyItem> stickers */ 1:
                    this.binaryReadMap1(message.stickers, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: StickerFrecency["stickers"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof StickerFrecency["stickers"] | undefined, val: StickerFrecency["stickers"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.fixed64().toString();
                    break;
                case 2:
                    val = FrecencyItem.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field discord_protos.discord_users.v1.StickerFrecency.stickers");
            }
        }
        map[key ?? "0"] = val ?? FrecencyItem.create();
    }
    internalBinaryWrite(message: StickerFrecency, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<fixed64, discord_protos.discord_users.v1.FrecencyItem> stickers = 1; */
        for (let k of Object.keys(message.stickers)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Bit64).fixed64(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            FrecencyItem.internalBinaryWrite(message.stickers[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.StickerFrecency
 */
export const StickerFrecency = new StickerFrecency$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FavoriteEmojis$Type extends MessageType<FavoriteEmojis> {
    constructor() {
        super("discord_protos.discord_users.v1.FavoriteEmojis", [
            { no: 1, name: "emojis", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FavoriteEmojis>): FavoriteEmojis {
        const message = { emojis: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FavoriteEmojis>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FavoriteEmojis): FavoriteEmojis {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string emojis */ 1:
                    message.emojis.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FavoriteEmojis, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string emojis = 1; */
        for (let i = 0; i < message.emojis.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.emojis[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.FavoriteEmojis
 */
export const FavoriteEmojis = new FavoriteEmojis$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmojiFrecency$Type extends MessageType<EmojiFrecency> {
    constructor() {
        super("discord_protos.discord_users.v1.EmojiFrecency", [
            { no: 1, name: "emojis", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => FrecencyItem } }
        ]);
    }
    create(value?: PartialMessage<EmojiFrecency>): EmojiFrecency {
        const message = { emojis: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmojiFrecency>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmojiFrecency): EmojiFrecency {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, discord_protos.discord_users.v1.FrecencyItem> emojis */ 1:
                    this.binaryReadMap1(message.emojis, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: EmojiFrecency["emojis"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof EmojiFrecency["emojis"] | undefined, val: EmojiFrecency["emojis"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = FrecencyItem.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field discord_protos.discord_users.v1.EmojiFrecency.emojis");
            }
        }
        map[key ?? ""] = val ?? FrecencyItem.create();
    }
    internalBinaryWrite(message: EmojiFrecency, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, discord_protos.discord_users.v1.FrecencyItem> emojis = 1; */
        for (let k of Object.keys(message.emojis)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            FrecencyItem.internalBinaryWrite(message.emojis[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.EmojiFrecency
 */
export const EmojiFrecency = new EmojiFrecency$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplicationCommandFrecency$Type extends MessageType<ApplicationCommandFrecency> {
    constructor() {
        super("discord_protos.discord_users.v1.ApplicationCommandFrecency", [
            { no: 1, name: "application_commands", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => FrecencyItem } }
        ]);
    }
    create(value?: PartialMessage<ApplicationCommandFrecency>): ApplicationCommandFrecency {
        const message = { applicationCommands: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ApplicationCommandFrecency>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplicationCommandFrecency): ApplicationCommandFrecency {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, discord_protos.discord_users.v1.FrecencyItem> application_commands */ 1:
                    this.binaryReadMap1(message.applicationCommands, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: ApplicationCommandFrecency["applicationCommands"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ApplicationCommandFrecency["applicationCommands"] | undefined, val: ApplicationCommandFrecency["applicationCommands"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = FrecencyItem.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field discord_protos.discord_users.v1.ApplicationCommandFrecency.application_commands");
            }
        }
        map[key ?? ""] = val ?? FrecencyItem.create();
    }
    internalBinaryWrite(message: ApplicationCommandFrecency, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, discord_protos.discord_users.v1.FrecencyItem> application_commands = 1; */
        for (let k of Object.keys(message.applicationCommands)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            FrecencyItem.internalBinaryWrite(message.applicationCommands[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.ApplicationCommandFrecency
 */
export const ApplicationCommandFrecency = new ApplicationCommandFrecency$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FavoriteSoundboardSounds$Type extends MessageType<FavoriteSoundboardSounds> {
    constructor() {
        super("discord_protos.discord_users.v1.FavoriteSoundboardSounds", [
            { no: 1, name: "sound_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 6 /*ScalarType.FIXED64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<FavoriteSoundboardSounds>): FavoriteSoundboardSounds {
        const message = { soundIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FavoriteSoundboardSounds>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FavoriteSoundboardSounds): FavoriteSoundboardSounds {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated fixed64 sound_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.soundIds.push(reader.fixed64().toBigInt());
                    else
                        message.soundIds.push(reader.fixed64().toBigInt());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FavoriteSoundboardSounds, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated fixed64 sound_ids = 1; */
        if (message.soundIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.soundIds.length; i++)
                writer.fixed64(message.soundIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.FavoriteSoundboardSounds
 */
export const FavoriteSoundboardSounds = new FavoriteSoundboardSounds$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FrecencyUserSettings$Type extends MessageType<FrecencyUserSettings> {
    constructor() {
        super("discord_protos.discord_users.v1.FrecencyUserSettings", [
            { no: 1, name: "versions", kind: "message", T: () => Versions },
            { no: 2, name: "favorite_gifs", kind: "message", T: () => FavoriteGIFs },
            { no: 3, name: "favorite_stickers", kind: "message", T: () => FavoriteStickers },
            { no: 4, name: "sticker_frecency", kind: "message", T: () => StickerFrecency },
            { no: 5, name: "favorite_emojis", kind: "message", T: () => FavoriteEmojis },
            { no: 6, name: "emoji_frecency", kind: "message", T: () => EmojiFrecency },
            { no: 7, name: "application_command_frecency", kind: "message", T: () => ApplicationCommandFrecency },
            { no: 8, name: "favorite_soundboard_sounds", kind: "message", T: () => FavoriteSoundboardSounds }
        ]);
    }
    create(value?: PartialMessage<FrecencyUserSettings>): FrecencyUserSettings {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FrecencyUserSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FrecencyUserSettings): FrecencyUserSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional discord_protos.discord_users.v1.Versions versions */ 1:
                    message.versions = Versions.internalBinaryRead(reader, reader.uint32(), options, message.versions);
                    break;
                case /* optional discord_protos.discord_users.v1.FavoriteGIFs favorite_gifs */ 2:
                    message.favoriteGifs = FavoriteGIFs.internalBinaryRead(reader, reader.uint32(), options, message.favoriteGifs);
                    break;
                case /* optional discord_protos.discord_users.v1.FavoriteStickers favorite_stickers */ 3:
                    message.favoriteStickers = FavoriteStickers.internalBinaryRead(reader, reader.uint32(), options, message.favoriteStickers);
                    break;
                case /* optional discord_protos.discord_users.v1.StickerFrecency sticker_frecency */ 4:
                    message.stickerFrecency = StickerFrecency.internalBinaryRead(reader, reader.uint32(), options, message.stickerFrecency);
                    break;
                case /* optional discord_protos.discord_users.v1.FavoriteEmojis favorite_emojis */ 5:
                    message.favoriteEmojis = FavoriteEmojis.internalBinaryRead(reader, reader.uint32(), options, message.favoriteEmojis);
                    break;
                case /* optional discord_protos.discord_users.v1.EmojiFrecency emoji_frecency */ 6:
                    message.emojiFrecency = EmojiFrecency.internalBinaryRead(reader, reader.uint32(), options, message.emojiFrecency);
                    break;
                case /* optional discord_protos.discord_users.v1.ApplicationCommandFrecency application_command_frecency */ 7:
                    message.applicationCommandFrecency = ApplicationCommandFrecency.internalBinaryRead(reader, reader.uint32(), options, message.applicationCommandFrecency);
                    break;
                case /* optional discord_protos.discord_users.v1.FavoriteSoundboardSounds favorite_soundboard_sounds */ 8:
                    message.favoriteSoundboardSounds = FavoriteSoundboardSounds.internalBinaryRead(reader, reader.uint32(), options, message.favoriteSoundboardSounds);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FrecencyUserSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional discord_protos.discord_users.v1.Versions versions = 1; */
        if (message.versions)
            Versions.internalBinaryWrite(message.versions, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.FavoriteGIFs favorite_gifs = 2; */
        if (message.favoriteGifs)
            FavoriteGIFs.internalBinaryWrite(message.favoriteGifs, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.FavoriteStickers favorite_stickers = 3; */
        if (message.favoriteStickers)
            FavoriteStickers.internalBinaryWrite(message.favoriteStickers, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.StickerFrecency sticker_frecency = 4; */
        if (message.stickerFrecency)
            StickerFrecency.internalBinaryWrite(message.stickerFrecency, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.FavoriteEmojis favorite_emojis = 5; */
        if (message.favoriteEmojis)
            FavoriteEmojis.internalBinaryWrite(message.favoriteEmojis, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.EmojiFrecency emoji_frecency = 6; */
        if (message.emojiFrecency)
            EmojiFrecency.internalBinaryWrite(message.emojiFrecency, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.ApplicationCommandFrecency application_command_frecency = 7; */
        if (message.applicationCommandFrecency)
            ApplicationCommandFrecency.internalBinaryWrite(message.applicationCommandFrecency, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* optional discord_protos.discord_users.v1.FavoriteSoundboardSounds favorite_soundboard_sounds = 8; */
        if (message.favoriteSoundboardSounds)
            FavoriteSoundboardSounds.internalBinaryWrite(message.favoriteSoundboardSounds, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message discord_protos.discord_users.v1.FrecencyUserSettings
 */
export const FrecencyUserSettings = new FrecencyUserSettings$Type();
